import { createApp } from 'vue'
import App from './App.vue'

// Tell Vue to use the plugin
const mountPoint = document.createElement("div");
const container = document.getElementById("crc-home-zoekbalk");
container.attachShadow({ mode: "open" }).appendChild(mountPoint);

createApp(App).mount(mountPoint)

