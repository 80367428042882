<template>
  <div class="text-center w-100 p-3">
    <h3 class="text-white my-3">ONZE VOORRAAD</h3>
    <div class="d-flex flex-wrap justify-content-center">
        <div class="btn-group my-3 mx-1 rounded-pill bg-white">
            <button :class="activeBtn === 'occasion' ? 'btn-custom-active' : ''" @click="setActive('occasion')" class="px-3 border-0 bg-white rounded-pill">Occasion</button>
            <button :class="activeBtn === 'nieuw' ? 'btn-custom-active' : ''" @click="setActive('nieuw')" class="px-3 border-0 bg-white rounded-pill">Nieuw</button>
            <button :class="activeBtn === 'alle' ? 'btn-custom-active' : ''" @click="setActive('alle')" class="px-4 border-0 bg-white rounded-pill">Alle</button>
        </div>
        <div class="dropdowns d-flex flex-wrap justify-content-center my-3">
          <div class="custom-select-wrapper bg-white mx-1 rounded-pill p-1">
              <select class="custom-select border-0 bg-white rounded-pill p-2" v-model="selectedBrand">
                  <option value="">Alle merken</option>
                  <option v-for="brand in brands" :key="brand">{{ brand }}</option>
              </select>
          </div>
          <div class="custom-select-wrapper bg-white mx-1 rounded-pill p-1">
              <select :disabled="!selectedBrand" class="custom-select border-0 bg-white rounded-pill p-2" v-model="selectedModel">
                  <option value="">Alle modellen</option>
                  <option v-for="model in models" :key="model" :value="model">{{ model }}</option>
              </select>
          </div>
          <div class="custom-select-wrapper bg-white mx-1 rounded-pill p-1">
            <select class="custom-select border-0 bg-white rounded-pill p-2" v-model="selectedPrice">
              <option value="">Prijs tot</option>
              <option v-for="price in prices" :key="price" :value="price">€{{ price }}</option>
            </select>
          </div>
          <div class="custom-select-wrapper bg-white mx-1 rounded-pill p-1">
            <select class="custom-select border-0 bg-white rounded-pill p-2" v-model="selectedMileage">
              <option value="">Km-stand tot</option>
              <option v-for="mileage in mileages" :key="mileage" :value="mileage">{{ mileage }} km</option>
            </select>
          </div>
        </div>
      <button class="btn-custom-view my-3 px-4 rounded-pill border-0 mx-1 bg-danger text-white" @click="applyFiltersAndNavigate">Zoeken</button>
      <div v-if="loading">Loading...</div>
      <div v-if="error">{{ error.message }}</div>
    </div>
  </div>
</template>

<script>
import { useCookies } from "vue3-cookies";

export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: 'HomeFilter',
  data() {
    return {
      activeBtn: 'alle',
      brands:['Honda'],
      selectedBrand:'Honda',
      models:['CR-V'],
      selectedModel:'CR-V',
      prices:['39.000'],
      selectedPrice:'39.000',
      mileages:['0'],
      selectedMileage:'0',
      loading:false,
      error:null

    }
  },
  methods: {
    setActive(button)
    {
      this.activeBtn = button
    },
    applyFiltersAndNavigate()
    {
      this.cookies.set("cars_selected_filters", {
        selectedModel:this.selectedModel,
        selectedBrand:this.selectedBrand,
        selectedPrice:this.selectedPrice,
        selectedMileage:this.selectedMileage,
      })
    }
  },
  mounted() {

  },

}
</script>

<style>


</style>
