<template>
  <HomeFilter />
</template>

<script>
import HomeFilter from './components/HomeFilter.vue'

export default {
  name: 'App',
  components: {
    HomeFilter
  },
  mounted() {

  },
  beforeCreate() {
      let link = document.createElement('link');
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('href', 'https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css');
      document.getElementById("crc-home-zoekbalk").shadowRoot.appendChild(link);
      let link2 = document.createElement('link');
      link2.setAttribute('rel', 'stylesheet');
      link2.setAttribute('href', '/zoekbalk/style.css');
      document.getElementById("crc-home-zoekbalk").shadowRoot.appendChild(link2);
  }
}
</script>
